import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { ContactService, IContactDto, IIdTypeDto } from '@abcfinlab/api/contact';
import { inject } from '@angular/core';
import {
    ICalculationSettingsDto, IInhouseQuoteDto,
    ILeasingQuoteOverviewDto,
    RetailerQuoteService,
    IRetailerQuoteResultDto,
    ISaleAndLeaseBackCodeDto,
    QuoteService,
    SaleAndLeaseBackService,
    SettingsService,
} from '@abcfinlab/api/global';
import { ObjectgroupControllerService as ObjectgroupService, ObjectGroupDto as IObjectGroupDto } from '@abcfinlab/api-global';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const getLesseeResolver: ResolveFn<IContactDto> = (
    route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
) => {
    let type: IIdTypeDto = IIdTypeDto.Crefo;
    let id: string = route.paramMap.get('lesseeId');
    if (route.queryParams.id) {
        type = IIdTypeDto.Id;
        id = route.queryParams.id;
    }
    return inject(ContactService).getById({ id, idType: type }).pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getInhouseQuoteResolver: ResolveFn<IInhouseQuoteDto> = (
    route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
) => {
    return inject(QuoteService).getQuoteByIdV({ quoteId: route.paramMap.get('quoteId') }).pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getRetailerQuoteResolver: ResolveFn<IRetailerQuoteResultDto> = (
    route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
) => {
    return inject(RetailerQuoteService).get({ leasingQuoteId: route.paramMap.get('id') }).pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getObjectGroupsResolver: ResolveFn<Array<IObjectGroupDto>> = () => {
    return inject(ObjectgroupService).getObjectgroups().pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getCalculationSettingsResolver: ResolveFn<ICalculationSettingsDto> = () => {
    return inject(SettingsService).getCalculationSettingsDto().pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getSlbCodesResolver: ResolveFn<Array<ISaleAndLeaseBackCodeDto>> = () => {
    return inject(SaleAndLeaseBackService).getSaleAndLeaseBackCodes().pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getSmbCodesResolver: ResolveFn<Array<ISaleAndLeaseBackCodeDto>> = () => {
    return inject(SaleAndLeaseBackService).getSmbCodes().pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getQuotesForLesseeResolver: ResolveFn<ILeasingQuoteOverviewDto> = (
    route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
) => {
    return inject(QuoteService).getQuotesForLesse({ lesseeId: route.queryParams.id }).pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};

export const getLesseesForQuoteResolver: ResolveFn<IContactDto> = (
    route: ActivatedRouteSnapshot, _state: RouterStateSnapshot,
) => {
    return inject(ContactService).getById({ id: route.paramMap.get('lesseeId'), idType: IIdTypeDto.Id }).pipe(
        catchError((err) => {
            return throwError(err);
        }),
    );
};
